import styles from '../../styles/sass/layout/Mission.module.scss'

export default function Mission() {
    return (
        <div className={styles.mission}>
            <h2 className={styles.missionTitle}>
                Légy otthon önmagadban, olvassuk együtt az életed!
            </h2>
            <div className={styles.missionDescriptionContainer}>
                <h2 className={styles.missionDescription}>
                Jelen lenni önmagunkban az egyik legnagyobb kihívás, hiszen az idevezető királyi út gyakran akadályokkal teli. 
                Sosem késő úgy dönteni, hogy belevágunk ebbe az utazásba! Ezen a helyen megtanulhatod olvasni magad a sorok között, hogy felépíthesd a saját történeted!
                </h2>
            </div>
        </div>
    );

}