import Image from 'next/image';
import Link from "next/link";
import styles from '../../styles/sass/layout/Articles.module.scss';
import ArticleCard from '../cards/articleCard';

const Articles = ({ title, articles }) => {
    /* const getCategoryName = (category) => {
        return categories.find(cat => cat.slug === category).name;
    } */
    return (
        <>
            {articles &&
                <div className={styles.articlesSection}>
                    <div className={styles.articlesMarkContainer}>
                        <span>
                            <Image
                                src='/img/logo-secondarymark.png'
                                width={100}
                                height={100}
                                style={{
                                    width: '100px',
                                    height: 'auto'
                                }}
                                alt="Cikkek szekciót elválasztó logo" />
                        </span>
                    </div>
                    <div className={styles.articlesHeadingSecondaryContainer}>
                        {title && <h1 className={styles.articlesHeadingSecondary}>
                            {title}
                        </h1>
                            || <h2 className={styles.articlesHeadingSecondary}>
                                BLOG
                            </h2>
                        }
                    </div>
                    {!title &&
                        <div className={styles.articlesSeeAll} >
                            <Link href="/blog"><span className={styles.articlesSeeAllText}>ÖSSZES CIKK<ion-icon name="chevron-forward-outline"></ion-icon></span></Link>
                        </div>}

                    <div className={styles.articlesCardContainer}>
                        {articles.map((article) => (
                            <ArticleCard
                                key={article.id}
                                article={article}
                            />
                        ))}
                    </div>
                </div >
                || !articles && null
            }
        </>
    );
};

export default Articles;