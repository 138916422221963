import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import styles from '../../styles/sass/components/ArticleCard.module.scss';

export default function ArticleCard({ article }) {
    const [articleHover, setArticleHover] = useState(false)

    const articleMouseEnter = () => {
        setArticleHover(true);
    }
    const articleMouseLeave = () => {
        setArticleHover(false);
    }

    return (
        <Link href={`/${article.columnSlug}/${article.slug}`} >

            <div className={styles.articleCard}
                onMouseEnter={articleMouseEnter}
                onTouchStart={articleMouseEnter}
                onTouchEnd={articleMouseLeave}
                onMouseLeave={articleMouseLeave}
            >
                <div
                    className={`${styles.articleCardImageContainer}`}
                >
                    {articleHover &&
                        <div className={styles.articleCardImageHover}>
                            <h3 className={styles.headingTertiary}>ELOLVASOM</h3>
                            <div className='text-6xl'>&rarr;</div>
                        </div>}
                    <Image
                        src={article.coverImage}
                        width={440}
                        height={300}
                        style={{
                            objectFit: 'cover',
                            layout: 'responsive',
                            width: '100%',
                            height: '300px',
                            objectPosition: '60% 30%'
                        }}
                        alt={article.coverAlt}
                    />
                </div>
                <div className={styles.articleCardTextContainer}>
                    <p className={styles.articleCardCategory}>
                        {article.columnName}
                    </p>
                    <h3 className={styles.articleCardTitle}>
                        {article.title}
                    </h3>
                    <p className={styles.articleCardLead}>
                        {article.lead}
                    </p>
                    <p className='text-end'>
                        {article.publishedAt.toString().slice(0, 10)}
                    </p>
                </div>
            </div>
        </Link>
    );
}
