import styles from '../../styles/sass/layout/About.module.scss'
import Image from 'next/image'
import Link from 'next/link'

import aboutWithoutQuote from '../../../public/img/about-without-quote.jpeg';

export default function About() {
    return (
        <div className={styles.about}>
            <div className={styles.aboutDescriptionContainer}>
                <h2 className={`${styles.headingSecondary} text-center`}>
                    Bemutatkozás
                </h2>
                <div className={styles.aboutDescriptionText}>
                    <p className={styles.aboutDescriptionTextParagraph}>
                        Az önismeret nem egy végállomás, hanem egy szemléletmód, ami az életünk során segít minket abban, hogy a lehető legjobb önmagunk lehessünk.
                        A legfontosabb célom, hogy a hozzám fordulók ezt elsajátítva megtanuljanak saját terapeutájuk lenni.
                    </p>
                </div>
                <div className={styles.aboutDescriptionButtonContainer}>
                    <Link href='/rolam' passHref legacyBehavior>
                        <a className={styles.btnSecondary}>
                            <span>TÖBBET RÓLAM</span>
                        </a>
                    </Link>
                </div>
            </div>
            <div className={styles.aboutPictureSide}>
                <div className={styles.aboutPictureContainer}>
                    <Image
                        src={aboutWithoutQuote}
                        className={styles.aboutPicture}
                        fill
                        style={{
                            objectFit: 'cover',
                            objectPosition: '70% 0'
                        }}
                        alt="Szabó Jennifer pszichológus"
                    />
                </div>
            </div>
        </div>
    );

}