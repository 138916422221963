import Link from "next/link";
import Image from "next/image";
import styles from '../../styles/sass/components/ServiceCard.module.scss'

export default function ServiceCard(props) {

    return (
        <div className={styles.serviceCard}>
            <div className={`${styles.serviceCardSide} ${styles.serviceCardSideFront}`}>
                <div className={`${styles.serviceCardImageContainer}`}>
                    <Image
                        src={props.frontImage}
                        width={300}
                        height={300}

                        style={{
                            layout: 'responsive',
                            objectFit: 'cover',
                            width: '100%'
                        }}
                        alt={props.frontImageAlt}
                    />
                </div>
                <div className={`${styles.serviceCardTitleContainer}`}>
                    <h2 className={styles.serviceCardTitle}>
                        {props.frontTitle}
                    </h2>
                </div>

            </div>
            <div className={`${styles.serviceCardSide} ${styles.serviceCardSideBack}`}>
                <div className={styles.serviceCardDetailsContainer}>
                    {props.list &&
                        <ul className={styles.serviceCardDetails}>
                            {props.list.map((item, i) => {
                                return <li key={i}>{item}</li>
                            })}
                        </ul>
                        || props.text &&
                        <div className={styles.serviceCardDetails}>
                            <p className="text-center p-6 text-3xl leading-normal">
                                {props.text}
                            </p>
                        </div>
                    }
                </div>
                <div className={styles.serviceCardButtonContainer}>
                    <Link href={props.url} passHref legacyBehavior>
                        <a className={`${styles.btnSecondary} ${styles.serviceCardButton}`}>
                            Részletek
                        </a>
                    </Link>
                </div>
            </div>
        </div >
    );
}

