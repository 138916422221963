import styles from '../../styles/sass/layout/Services.module.scss'
import Image from "next/image";
import ServiceCard from '../cards/serviceCard';

export default function Services() {
    return (
        <div className={styles.services}>
            <h2 className={styles.headingSecondary}>
                Szolgáltatások
            </h2>
            <div className={styles.servicesContainer}>
                <ServiceCard
                    frontImage='/img/szolgaltatasok/szemelyes-tanacsadas.png'
                    frontImageAlt='Személyes tanácsadás ábra'
                    frontTitle='Személyes tanácsadás'
                    url='/szolgaltatasok/szemelyes-tanacsadas'
                    text="Teret biztosít az érzelmek és a személyes tapasztalatok felfedezésére, 
                        hogy hatékonyabb megküzdési készségeket alakítsunk ki a mindennapi élet kihívásival szemben, eszközöket kínál az egészségesebb kapcsolati működésre."
                />
                <ServiceCard
                    frontImage='/img/szolgaltatasok/online-tanacsadas.png'
                    frontImageAlt='Online tanácsadás ábra'
                    frontTitle='Online tanácsadás'
                    url='/szolgaltatasok/online-tanacsadas'
                    text="Az online tér lehetőséget biztosít bármilyen távolság áthidalására 
                    országon belül és országhatárokon túl is annak érdekében, hogy hatékonyabb megküzdési készségeket alakítsunk ki a mindennapi élet kihívásival szemben."
                />
                <ServiceCard
                    frontImage='/img/szolgaltatasok/csoportos-onismeret.png'
                    frontImageAlt='Csoportos önismeret ábra'
                    frontTitle='Csoport'
                    url='/szolgaltatasok/csoport'
                    text="Lehetőséget teremt a saját élményeinket a csoport tagjaival közösen feldolgozni, 
                    azokat új perspektívába helyezni, miközben átélhetjük a sorsközösség élményét, hogy nem vagyunk egyedül."
                />
            </div>
        </div>
    );

}