import Image from 'next/image';
import hero from '../../../public/img/szabojennifer-hero.jpeg';
import styles from '../../styles/sass/layout/Hero.module.scss';


export default function Hero() {

    return (
        <div className={styles.hero}>
            <div className={styles.heroImgBox}>
                <div className={styles.heroText}>
                    <h1 className={` uppercase ${styles.headingSecondary}`}>
                        Üdvözöllek
                    </h1>
                    <h2 className={` uppercase ${styles.headingPrimary} mt-8 mb-4`}>
                        Szabó Jennifer vagyok
                    </h2>
                    <h3 className='text-3xl md:text-4xl block font-light uppercase tracking-widest'>Pszichológus és irodalomterapeuta</h3>
                </div>
                <Image
                    src={hero}
                    className={styles.heroImg}
                    priority
                    placeholder='blur'
                    quality={100}
                    fill
                    sizes='100vw'
                    style={{
                        objectFit: 'cover',
                        objectPosition: '0 55%'
                    }}
                    alt="Szabó Jennifer pszichológus"
                />
            </div>
        </div>
    );
}