import Head from 'next/head'
import Script from 'next/script'
import About from '../components/layout/about'
import Articles from '../components/layout/articles'
import Hero from '../components/layout/hero'
import Mission from '../components/layout/mission'
import Services from '../components/layout/services'
import styles from '../styles/sass/pages/Home.module.scss'

const API_BASE_URL = process.env.API_BASE_URL || '';
const PUBLIC_API_KEY = process.env.PUBLIC_API_KEY || '';
const ARTICLE_REVALIDATE = process.env.ARTICLE_REVALIDATE || 43200;

export default function Home({ articles }) {
  return (
    <>
      <Head>
        <title>Szabó Jennifer - Pszichológus, irodalomterapeuta</title>
        <meta name="description" content="Pszichológiai tanácsadás és konzultáció, csoportos folyamatok Budapesten és online. 
        Önismeret, konfliktuskezelés, elakadások, életvezetési nehézségek." />
        <meta property="og:title" content="Szabó Jennifer - Pszichológus, irodalomterapeuta" />
        <meta property="og:type" content="article" />
        <meta property="og:description" content="Pszichológiai tanácsadás és konzultáció, csoportos folyamatok Budapesten és online. 
        Önismeret, konfliktuskezelés, elakadások, életvezetési nehézségek." />
        <meta property="og:url" content="https://szabojennifer.hu/" />
        <meta property="og:image" content="/img/fb-cover.png" />
        <meta property="og:image:alt" content="Szabó Jennifer egyik szemét egy könyvvel eltakarja" />
      </Head>
      <section className={styles.sectionHero}>
        {<Hero />}
      </section>
      <section className={styles.sectionMission}>
        <Mission />
      </section>
      <section className={styles.sectionAbout}>
        <About />
      </section>
      <section className={styles.sectionServices}>
        <Services />
      </section>

      {
        articles && articles.length > 0 &&
        <section className={styles.sectionStories}>
          <Articles
            articles={articles}
          />
        </section>
      }
    </>
  )
}

export async function getStaticProps() {
  try {
    const res = await fetch(API_BASE_URL + '/articles', {
      next: { revalidate: ARTICLE_REVALIDATE },
      headers: { 'Api-Key': PUBLIC_API_KEY },
    });
    if (!res || !res.ok) {
      return {
        props: {},
      };
    }
    const articles = await res.json();
    console.log('articles:', articles);
    return {
      props: { articles },
      // Next.js will attempt to re-generate the page:
      // - When a request comes in
      // - At most once every 180 seconds
      revalidate: 120,
    };
  } catch (error) {
    return { props: {} };
  }
}